<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <browse-route class="w-full" v-validate="'required'" name="route_id" label="Route" v-model="route_id"></browse-route>
          <span class="text-danger text-sm" v-show="errors.has('route_id')">{{errors.first('route_id')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
         <browse-select-vehicle-type class="w-full" :value="vehicle_type_id" v-model="vehicle_type_id"></browse-select-vehicle-type>
        </div>
      </div>
      <div class="vx-row mb-2" v-show="!$route.params.id">
        <div class="vx-col w-full">
          <vs-checkbox name="is_generate" ref="is_generate" v-model="is_generate" @change="commodity_id=null"><small>Apply Price List to All Commodities</small></vs-checkbox>
        </div>
      </div>
      <div class="vx-row mb-2" v-show="!is_generate">
        <div class="vx-col w-full">
          <browse-select-commodity class="w-full" :value="commodity_id" v-model="commodity_id"></browse-select-commodity>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="price_tonase" label="Price per Kg" v-model="price_tonase"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('price_tonase')">{{errors.first('price_tonase')}}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="min_tonase" label="Minimal Kg" v-model="min_tonase"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('min_tonase')">{{errors.first('min_tonase')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="price_volume" label="Price per m3" v-model="price_volume"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('price_volume')">{{errors.first('price_volume')}}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="min_volume" label="Minimal m3" v-model="min_volume"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('min_volume')">{{errors.first('min_volume')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="price_item" label="Price per Item" v-model="price_item"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('price_item')">{{errors.first('price_item')}}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="min_item" label="Minimal Item" v-model="min_item"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('min_item')">{{errors.first('min_item')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="price_full" label="Price Full" v-model="price_full"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('price_full')">{{errors.first('price_full')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <small>Active Price</small>
          <vs-checkbox class="w-full" v-model="is_active"></vs-checkbox>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Description" v-model="description"></vs-input>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <vs-divider></vs-divider>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
// import BrowseCommodity from '@browse/Commodity.vue'
import BrowseRoute from '@browse/Route.vue'
// import BrowseVehicleType from '@browse/VehicleType.vue'
import BrowseSelectVehicleType from '@browse_select/VehicleType.vue'
import BrowseSelectCommodity from '@browse_select/Commodity.vue'

export default {
  components:{
    // BrowseCommodity,
    BrowseSelectCommodity,
    BrowseRoute,
    // BrowseVehicleType
    BrowseSelectVehicleType
  },
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      name: "",
      route_id: null,
      commodity_id: null,
      vehicle_type_id: null,
      container_type_id: null,
      price_tonase: 0,
      min_tonase: 0,
      price_volume: 0,
      min_volume: 0,
      price_item: 0,
      min_item: 0,
      price_full: 0,
      is_generate: false,
      is_active: true,
      description: ""
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'price_lists/store',
      dispatchUpdate: 'price_lists/update',
      dispatchShow: 'price_lists/show'
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          contract_id: this.$route.params.contract_id,
          name: this.name,
          route_id: this.route_id,
          commodity_id: this.commodity_id,
          vehicle_type_id: this.vehicle_type_id,
          container_type_id: this.container_type_id,
          price_tonase: this.price_tonase,
          min_tonase: this.min_tonase,
          price_volume: this.price_volume,
          min_volume: this.min_volume,
          price_item: this.price_item,
          min_item: this.min_item,
          price_full: this.price_full,
          is_active: this.is_active,
          description: this.description,
          is_generate: this.is_generate,
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.name = data.name
      this.route_id = data.route_id
      this.commodity_id = data.commodity_id
      this.vehicle_type_id = data.vehicle_type_id
      this.container_type_id = data.container_type_id
      this.price_tonase = data.price_tonase
      this.min_tonase = data.min_tonase
      this.price_volume = data.price_volume
      this.min_volume = data.min_volume
      this.price_item = data.price_item
      this.min_item = data.min_item
      this.price_full = data.price_full
      this.is_active = data.is_active
      this.description = data.description
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>