<template>
    <div>
        <small class="ml-2">Vehicle Type</small>
        <v-select v-model="vehicle_type_id" :options="vehicle_types" name="vehicle_type_id" label="name" :reduce="e => e.id" @input='changeValue()'></v-select>
        <span class="text-danger text-sm" v-show="errors.has('vehicle_type_id')">{{errors.first('vehicle_type_id')}}</span>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from 'vue-select'

export default {
    props: ['value'],
    components:{
        vSelect
    },
    data(){
        return {
            vehicle_type_id: this.value
        }
    },
    computed:{
        ...mapState({
            vehicle_types: state => state.vehicle_types.all
        })
    },
    watch:{
        async value(val) {
            if (val) {
                this.vehicle_type_id = val
            } else {
                this.vehicle_type_id = null
            }
            this.changeValue()
        }
    },
    methods:{
        ...mapActions({
            dispatchAll: 'vehicle_types/all'
        }),
        changeValue(){
            let data = this.vehicle_types.find(x => x.id == this.vehicle_type_id);
            if(data != null) {
                this.$emit('input', data.id);
                this.$emit('on-change', data);
            }
            else {
                this.$emit('input', null);
                this.$emit('on-change', null);
            }
        },
    },
    mounted(){
        this.dispatchAll();
    }
}
</script>